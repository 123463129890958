import { Component } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { Subscription } from "rxjs";
import { AppService } from "./services/app.service";
export let browserRefresh = false;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  title = "angular-idle-timeout";
  subscription: Subscription;

  constructor(private router: Router) // private idle: Idle,
  // private keepalive: Keepalive,
  // private appService: AppService
  {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
    // sets an idle timeout of 20 seconds, for testing purposes.
    // idle.setIdle(20);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    // idle.setTimeout(5);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // idle.onIdleEnd.subscribe(() => {
    //   this.idleState = "No longer idle.";
    //   console.log(this.idleState);
    //   this.reset();
    // });

    // idle.onTimeout.subscribe(() => {
    //   this.idleState = "Timed out!";
    //   this.timedOut = true;
    //   console.log(this.idleState);
    //   localStorage.clear();
    //   this.router.navigate(["/"]);
    // });

    // idle.onIdleStart.subscribe(() => {
    //   this.idleState = "You've gone idle!";
    //   console.log(this.idleState);
    // });

    // idle.onTimeoutWarning.subscribe((countdown) => {
    //   this.idleState = "You will time out in " + countdown + " seconds!";
    //   console.log(this.idleState);
    // });

    // sets the ping interval to 15 seconds
    // keepalive.interval(15);

    // keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    // this.appService.getUserLoggedIn().subscribe((userLoggedIn) => {
    //   if (userLoggedIn) {
    //     idle.watch();
    //     this.timedOut = false;
    //   } else {
    //     idle.stop();
    //   }
    // });

    // this.reset();
  }

  // reset() {
  //   this.idle.watch();
  //   //xthis.idleState = 'Started.';
  //   this.timedOut = false;
  // }

  // stay() {
  //   this.reset();
  // }

  // logout() {
  //   this.appService.setUserLoggedIn(false);
  //   this.router.navigate(["/"]);
  //   localStorage.clear();
  // }
}
